import React from "react"
import CategoryWrapper from "../../components/CategoryWrapper"

function MenHoodiesPage() {
  return (
    <CategoryWrapper
      seoTitle="Trøjer"
      gender="herre"
      category="hoodies"
    />
  )
}

export default MenHoodiesPage
